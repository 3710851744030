<template>
	<div class="warpper">
		<ul class="page-box">
			<li id="page1">
				<!-- <div class="main1">
					<p class="main1-1"><img src="../../assets/img/page1_img2.png" class="logo" /></p>
				</div> -->
				<div class="page1-back-box">
					<div class="main1-2">
						<p>全平台公测正式开启</p>
						<p><img src="../../assets/img/page1_img5.png" /></p>
					</div>
					<div class="main1-3 clearfix">
						<img src="../../assets/img/page1_img7.png" class="page1_img" @click="toLink('https://dlsk.52wgame.com:8020/download/android/NeoGame_5000_a01_3.5.1.apk')"/>
						<img src="../../assets/img/page1_img6.png" class="page1_img" @click="toLink('https://apps.apple.com/cn/app/%E6%97%B6%E7%A9%BA%E9%A3%8E%E6%9A%B4-%E5%86%B3%E6%88%98%E6%89%8B%E6%B8%B8/id1536585240')"/>
					</div>
				</div>
			</li>
			<li id="page01">
				<div class="main01">
					<div class="page-titles-box">
						<img src="../../assets/img/page-titles.png" alt="" class="page-titles" />
						<!--<div class="More">More+</div>-->
					</div>
					<ul class="navctiveUl">
						<li class="navctiveli">
							<span
								class="fl"
								v-for="(item, index) in annoData"
								:key="index"
								:class="navctive == index ? 'navctive-active' : ''"
								@click="navctiveActiveButton(item.name, index)"
							>
								{{ item.name }}
							</span>
						</li>
						<div class="itemList" v-for="(item, index) in annoData" :key="index" :class="navctive == index ? 'navctive-List-active' : ''">
							<ul class="main1-04 box-border">
								<li  @click="detailslistButtons(items)" v-for="(items, indexs) in dataShow" :key="indexs">
									<span>【{{item.name}}】</span>
									<a href="javascript:void(0)" class="box-border">{{ items.title }}</a>
									<span>{{ items.sDate }}</span>
								</li>
								<div class="page"><span>{{currentPage+1}}/{{pageNum}}</span> <span style="margin-left: 5px;">共{{total}}条</span></div>
								<div class="height">
									<div class="btn-more prePage fl" @click="prePage" v-show="prePageShow">上一页</div>
									<div class="btn-more nextPage fr" @click="nextPage">下一页</div>
								</div>
							</ul>	
						</div>
						<li class="footer-nactive"></li>
					</ul>
					<!-- 活动banner -->
					<swiper class="swiper" :options="swiperOptionPage">
						<swiper-slide v-for="(item, index) in bannerList3" :key="index">
							<div class="bg-cover" :data-id="item.id">
								<div class="native-box">
									<div @click="toLink(item.url)" class="cover" :style="{ 'background-image': 'url(' + item.advertiseImages + ')' }">
										
									</div>									
								</div>
							</div>
						</swiper-slide>
						<div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
					</swiper>
				</div>
			</li>
			<li id="page2">
				<div class="main2">
					<swiper class="swiper " :options="swiperOption">
						<swiper-slide v-for="(item, index) in bannerList2" :key="index">
							<div class="bg-cover" :data-id="item.id"><div class="cover" :style="{ 'background-image': 'url(' + item.advertiseImages + ')' }"></div></div>
						</swiper-slide>
						<div class="swiper-pagination" slot="pagination"></div>
						<!-- 左右箭头 -->
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</swiper>
				</div>
			</li>
			<li id="page3">
				<div class="main3">
					<img src="../../assets/img/page3_title.png" alt="" class="page-title" />
					<swiper class="swiper " :options="swiperOptions">
						<div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%"></div>
						<swiper-slide v-for="(item, index) in bannerList" :key="index">
							<div class="bg-cover" :data-id="item.id">
								<div class="phone-box"><div class="cover" :style="{ 'background-image': 'url(' + item.advertiseImages + ')' }"></div></div>
								<div class="phone-box phone-boxs"><div class="cover" :style="{ 'background-image': 'url(' + item.advertiseImages + ')' }"></div></div>
							</div>
						</swiper-slide>
						<div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
						<div class="swiper-button-prevs swiper-button-white swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-nexts swiper-button-white swiper-button-next" slot="button-next"></div>
					</swiper>
				</div>
			</li>
			<li id="page4">
				<div class="main4">
					<img src="../../assets/img/page4_title.png" alt="" class="page-title" />
					<div class="title-box">
						<p class="title-content">在广阔无际的宇宙中</p>
						<p class="title-content">科技超前的决战勇士文明为了延续文明，开启时空风暴技术把决战勇士的火种传输到宇宙中的各种恒星中</p>
						<p class="margin"></p>
						<p class="title-content">有的文明先行达到恒星中的某个行星发展各自的技术，各种星球酝酿着决战勇士文明的诞生 从目前发现的星系里，太阳系里孕育着文明的火种，决战的勇士们在金星这颗星球上测试发展先前文明留下科技</p>
						<p class="title-content">经过长时间的测试发展，金星上的文明不断发展传播，科技也突飞猛进，各个未知领域的探索也不断尝试</p>
						<p class="margin"></p>
						<p class="title-content">在罗尼特宇宙研究中心，星际飞船的技术虽有进步，但还是处于发展之中，不能将决战勇士进行飞船传输到各个星球。</p>
						<p class="title-content">决战勇士向未知宇宙的探秘未曾停止，</p>
						<p class="title-content">金星的探测器在宇宙中不断探索恒星的情况</p>
						<p class="title-content">斯坦博士在发射的恒星探测器上传来一种信号，在不远处的水星上，又有决战勇士明文的火种在不断发展。</p>
						<p class="title-content">金星决战勇士异常兴奋，加快自己的科技研究，希望早日踏上这颗星球</p>
						
						<p class="margin"></p>
					</div>
				</div>
			</li>
			<li id="page5">
        <div class="footer">
          <div class="main5">
            <div class="footer-top footer-title">
              <p>本网络游戏适合年满18岁（含）以上的玩家使用 开发者:张家口卡洛斯网络技术有限公司 | 版本号：1.1 | 更新时间：2021.1.8</p>
              <p>抵制不良游戏,拒绝盗版游戏。注意自我保护,谨防受骗上当。适度游戏益脑,沉迷游戏伤身。合理安排时间,享受健康生活。</p>
              <p>张家口卡洛斯网络技术有限公司 | COPYRIGHT © 2019-2021 ALL RIGHTS RESERVED</p>
              <br />
              <p>点击加群 - <a style="color: #7bf5e0" target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=zmFb19J1u8BACJb2oxRTIsk0sm6R0YYL&jump_from=webapi">官方1群</a>
                | <a style="color: #7bf5e0" target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=D8MIp05k1z490PLOtEdQyj9nsCZ2QSd5&jump_from=webapi">官方2群</a>
                | <a style="color: #7bf5e0"  target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=QeppcUXJol1DgtMOdIAnS7jkTVqiW78Y&jump_from=webapi">官方3群</a>
              </p>


              <p><a href="#">企业QQ：正在开通（9:00-21:00）</a>	</p>
              <p><a href="#">微信公众号：决战时空风暴</a> </p>
              <p><a href="#">微信小程序：时空助手</a> </p>

            </div>

<!--            <div class="footer-bottom">-->

<!--                <div class="page-box">-->
<!--                  <router-link target="_blank" to='/pc/copy?type=0'>公司简介</router-link>-->
<!--                  <span>-</span>-->
<!--                  <router-link target="_blank" to='/pc/copy?type=4'>联系我们</router-link>-->
<!--                  <span>-</span>-->
<!--                  <router-link target="_blank" to='/pc/copy?type=6'>加入我们</router-link>-->
<!--                </div>-->
<!--              <div class="page-box">-->
<!--                <p><a href="https://skfbxy.52wgame.com/#/pages/xy/xy">用户协议</a> |-->
<!--                  <router-link target="_blank" to='/pc/copy?type=7'>隐私政策及儿童个人信息保护规则</router-link></p>-->

<!--                <p><a target="_blank" :href="require('../../assets/img/yyzz.jpg')">营业执照</a> ICP备案：冀ICP备19025191号-3 电信增值业务许可证：<a :href="require('../../assets/img/icpba.jpg')"">冀b2-20190755</a></p>-->
<!--                <p> 运营单位：杭州群游科技有限公司 著作权人：张家口卡洛斯网络技术有限公司 出版物号：ISBN 978-7-7979-9582-5 出版单位：杭州群游科技有限公司</p>-->
<!--                <p> 违法及不良信息举报中心 </p>-->
<!--                &lt;!&ndash; <div style="width:300px;margin:0 auto; padding:20px 0;">-->
<!--                  <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13073002000090" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">冀公网安备 13073002000090号</p></a>-->
<!--                </div> &ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
			</li>
			
		</ul>
		
	</div>
</template>

<script>
	// @ is an alias to /src
	import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
	import 'swiper/css/swiper.css';
	export default {
		name: 'Home',
		title: 'Loop mode / Infinite loop / Custom pagination',
		components: {
			Swiper,
			SwiperSlide
		},
		data() {
			return {
				navctive: 0,
				annoIndex: 0,
				annoData: [
					{
						name: '公告',
						id: '0',
						navctiveList:[{
              title: '海外版即将开发测试',
              date: '2021-6-13 08:00',
              sDate: '1/01',
              content: '海外决战勇士期待已久的新服即将开启，时空决战手游官方运作人员经过不断的优化测试， \
									针对海外的版本敬请关注。'
            },
							{
								id:"0", 
								title: '新区水星开区时间',
								date: '2021-1-1 08:00',
								sDate: '1/01',
								content: '决战勇士期待已久的新服即将开启<br />时空风暴-决战手游官方运作人员经过不断的优化测试，\
												带来令人愉悦的体验，在这个世界中，找回你曾经的记忆，这里将是你们的狂欢盛宴<br /> \
												现在正式宣布，开启时间：2021年1月18日中午12点'
							}, {
								id:"1", 
								title: '官方qq号及公众号',
								date: '2021-1-1 08:00',
								sDate: '1/01',
								content: '目前官方qq号只有3个,分别为: \
								<br />官方1群9634014<br />官方2群116946091<br />官方3群902634557<br />\
								其他大量的决战手游群为其他玩家自行建立，其他群发布的信息玩家时刻注意，谨防被骗！<br />\
								时空助手：打开微信，搜索时空助手，或则直接扫描二维码，就可以关注微信小程序\
								时空助手功能：找回密码，绑定手机，修改密码等功能'
							}, {
								id:"2", 
								title: '游戏简介',
								date: '2021-1-1 08:00',
								sDate: '1/01',
								content: '本游戏为正版独家授权,国产开发的游戏,公正公平。<br /> \
								免费游戏，自由交易、高自由度pk 一切物品都有怪物爆出<br />',
								
							}, {
								id:"3", 
								title: '人气火爆，时刻注意防骗',
								date: '2021-1-1 08:00',
								sDate: '1/01',
								content: '官方提醒：<br />为维护游戏环境公平公正，让玩家玩的有保障，不会有任何让你交钱，给予特殊好处等做法，\
								一切内容都以官方公告为准，不会有任何突破公告的内容，包括充值等，千万别贪图便宜，相信骗子。<br />\
								防骗注意事项：<br />\
								1.冒充官方管理员，会跟管理员头像一样，以招内部人员，推广人员等名义，给你特别好处，让你交保证金。<br />\
								2.冒充官方人员，让你支持游戏，给给予你消耗品，让你活跃游戏，说的让你真以为是官方人员，让你保密，让你交钱。<br />\
								3.qq上发个装备图片，让你交钱，这种骗子较多。<br /> \
								4.骗子号，跟买方和卖方同时聊交易的东西，然后，游戏里让你们两见面，当你钱付给骗子的时候，让你跟卖方引起冲突，\
								游戏里不仅要确认身份，还要确认对方跟你聊的qq号，防止被骗。<br /> \
								5.其他容易被骗的内容，多加注意。<br />'
							}
						]
					},
					{
						name: '活动',
						id: '1',
						navctiveList:[
							{
								id:"0",
								title: '签到奖励天天领',
								date: '2021-1-10 08:00',
								sDate: '1/01',
								content: '每天上线，签到领取双倍经验，双倍幸运'
							}
						]
					},
					{
						name: '维护',
						id: '2',
						navctiveList:[
							{
								id:"0",
								title: '测试活动1',
								date: '2020-12-1 08:00',
								sDate: '12/01',
								content: '测试活动1测试活动1测试活动1测试活动1测试活动1测试活动1测试活动1'
							}
						]
					},
					{
						name: '更新',
						id: '3',
						navctiveList:[
							{
								id:"0",
								title: '钱包物品升级',
								date: '2021-1-10 08:00',
								sDate: '1/01',
								content: '自动钱包增加自动捡物功能'
							}
						]
					}
					
				],
				makeBox: false,
				navctiveArrIndex: {
					name: ''
				},
				activeNum: 1,
				swiperOptionPage: {
					pagination: {
						el: '.swiper-pagination',
						clickable: true,
						renderBullet(index, className) {
							return `<span class="${className} swiper-pagination-bullet-custom"></span>`;
						}
					}
				},
				swiperOption: {
					speed: 600,
					//循环
					loop: true,
					parallax: true,
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					}
				},
				swiperOptions: {
					speed: 600,
					//循环
					loop: true,
					parallax: true,
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper-button-nexts',
						prevEl: '.swiper-button-prevs'
					}
				},
				//截图
				bannerList: [{
						advertiseImages: require('../../assets/img/bannerH1.png')
					},
					{
						advertiseImages: require('../../assets/img/bannerH2.png')
					},
					{
						advertiseImages: require('../../assets/img/bannerH5.png')
					}
				],
				//角色
				bannerList2: [{
						advertiseImages: require('../../assets/img/swiper2-bg1.png')
					},
					{
						advertiseImages: require('../../assets/img/swiper2-bg2.png')
					},
					{
						advertiseImages: require('../../assets/img/swiper2-bg3.png')
					},
					{
						advertiseImages: require('../../assets/img/swiper2-bg4.png')
					}
				],
				//顶部活动
				bannerList3: [{
						//advertiseImages: require('../../assets/img/page-banner.png'),
						advertiseImages:'https://gitee.com/krospath/imgs/raw/master/page-banner.png',
						url:'https://payh5.itkls.com'
					},
					{
						//advertiseImages: require('../../assets/img/page-banne1r.png'),
						advertiseImages:'https://gitee.com/krospath/imgs/raw/master/page-banne1r.png',
						url:'#'
					}
				],
				// 所有页面的数据
				totalPage: [],
							// 每页显示数量
				pageSize: 5,
				// 共几页
				pageNum: 1,
				// 当前显示的数据
				dataShow: [],
				// 默认当前显示第一页
				currentPage: 0,
				total:0,
				prePageShow:false
			};
		},
		components: {
			Swiper,
			SwiperSlide
		},
		directives: {
			swiper: directive
		},
		mounted() {
			this.pageFunction(this.navctive)
		},
		methods: {
			pageFunction(index){
				// 根据后台数据的条数和每页显示数量算出一共几页,得0时设为1 ;
				this.pageNum = Math.ceil(this.annoData[index].navctiveList.length / this.pageSize) || 1;
				for (let i = 0; i < this.pageNum; i++) {
				  // 每一页都是一个数组 形如 [['第一页的数据'],['第二页的数据'],['第三页数据']]
				  // 根据每页显示数量 将后台的数据分割到 每一页,假设pageSize为5， 则第一页是1-5条，即slice(0,5)，第二页是6-10条，即slice(5,10)...
				  this.totalPage[i] = this.annoData[index].navctiveList.slice(this.pageSize * i, this.pageSize * (i + 1))
				}
				// 获取到数据后显示第一页内容
				this.dataShow = this.totalPage[this.currentPage];
				this.total = this.annoData[index].navctiveList.length
				this.prePageShow = false
			},
			nextPage() {
			  if (this.currentPage === this.pageNum - 1) return ;
			  this.dataShow = this.totalPage[++this.currentPage];
			  this.prePageShow = true
			},
			// 上一页
			prePage() {
			   console.log(this.currentPage)
			  if (this.currentPage === 1) this.prePageShow = false;
			  this.dataShow = this.totalPage[--this.currentPage];
			},
			navctiveActiveButton(name, index) {
				this.currentPage = 0
				this.navctive = index;
				this.navctiveArrIndex.name = name;
				this.pageFunction(index)
				console.log("navctiveActiveButton",this.dataShow)
			},
			activeButton(id) {
				this.activeNum = id;
			},
			detailslistButtons(item) {
				this.$router.push({
					path: '/h5/details',
				});
				localStorage.setItem('nactive', JSON.stringify(item));
			},
			toLink(url) {
				window.open(url);
			}
		}
	};
</script>
<style scoped>
	.page{
			color: #333;
			padding: .5rem 0;
			text-align: right;
			font-size: 0.8rem;
		}
		.btn-more{
			font-size: 0.8rem;
			color:#fff;
			margin:0 0 .5rem;
			background-color: #2785ce;
			border-radius: 5px;
			padding: .2rem .3rem;
		}
		.btn-more img{
			width:0.8rem;
			text-align: right;
			margin-top: 3px;
			float: right;
		}
		.navctive-List-active{
			display: block !important;
		}
		.itemList{
			display: none;
		}
	.main1-3 {
		height: auto;
		overflow: hidden;
		padding: 0 1rem;
		margin-top: 2rem;
	}
	
	.page1_img {
		display: block;
		width: 48%;
		height: auto;
	}
	
	.page1_img:nth-child(1) {
		float: left;
	}
	
	.page1_img:nth-child(2) {
		float: right;
	}
	
	.page1-back-box {
		width: 100% !important;
		height: 17rem !important;
		background: url(../../assets/img/page1_cover.png) no-repeat center;
		background-size: cover;
		position: absolute !important;
		bottom: 0;
	}
	
	.logo {
		margin-top: 3rem;
		margin-left: 2rem;
	}
	
	.footer-nactive {
		width: 100%;
		height: 1.5rem;
		background: url(../../assets/img/nactive-footer.png) no-repeat center;
		background-size: cover;
		line-height: 1.5rem;
		margin-bottom: 5rem;
	}
	
	.main1-04 li span:nth-child(1) {
		width: 11%;
		color: #2785ce;
	}
	
	.main1-04 li a:nth-child(2) {
		width: 64%;
		color: #333;
		font-weight: 500;
	}
	
	.main01 .main1-04 {
		width: 100%;
		font-size: 0.5rem;
		background-color: #fff;
		padding: 0 2rem;
	}
	
	.main01 .main1-04 li {
		padding: 0.5rem 0;
		border-bottom: 1px dashed #ddd;
		font-size: 0.8rem;
	}
	
	.main1-04 li span:nth-child(3) {
		width: 25%;
		text-align: right;
		float: right;
		color: #999;
		font-size: 0.8rem;
	}
	
	.navctiveUl {
		padding: 0 15px;
	}
	
	.main3 .bg-cover {
		min-width: 320px;
	}
	
	.main3 .swiper-slide {
		padding: 0 !important;
	}
	
	.navctiveUl li.navctiveli {
		width: 100%;
		height: 3rem;
		background: url(../../assets/img/navctiveArr.png) no-repeat center;
		background-size: cover;
		line-height: 3rem;
	}
	
	.navctiveUl li.navctiveli span {
		width: 20%;
		text-align: center;
	}
	
	.navctiveUl li.navctiveli span.navctive-active {
		background: #fba40c;
		color: #fff;
	}
	
	.page-titles {
		display: block;
		width: 15rem;
		margin-bottom: 1rem;
		float: left;
	}
	
	.main01 .swiper-slide {
		padding: 10px;
	}
	
	.More {
		width: 130px;
		display: block;
		float: right;
		text-align: right;
		padding-right: 1rem;
		margin-top: 1rem;
		font-size: 1.5rem;
		font-weight: 400;
		color: #fba40c;
		cursor: pointer;
	}
	
	.page-titles-box {
		width: 100%;
		height: auto;
		overflow: hidden;
		margin-top: 2rem;
	}
	
	.main01 {
		overflow: hidden;
	}
	
	.main01 .cover {
		min-height: 180px;
		background-position: center !important;
		background-size: cover !important;
	}
	
	/deep/ .main01 .swiper-container-horizontal > .swiper-pagination-bullets {
		display: block;
	}
	
	/deep/ .main01 .swiper-container-horizontal > .swiper-pagination-bullets {
		top: 220px;
	}
	
	/deep/ .main01 .swiper-pagination {
		height: 20px !important;
	}
	
	.main01 .swiper-container {
		height: 240px !important;
	}
	
	/deep/ .main01 .swiper-pagination-bullet {
		width: 10%;
		height: 4px;
		border-radius: 5px;
		background-color: #f5f5f5;
	}
	
	/deep/ .swiper-pagination-bullet-active {
		background: #19bdfa !important;
	}
	
	.native-box {
		width: 100%;
		padding: 0.4rem;
		margin-left: -0.4rem;
		margin-top: -0.1rem;
		background: url(../../assets/img/back-boxs.png) no-repeat center;
		background-size: 100% 100%;
		min-height: 180px;
	}
	
	.width {
		width: 100%;
		height: 100%;
	}
	
	.page-ud {
		width: 4rem;
		margin: 0 auto;
		height: auto;
		overflow: hidden;
		font-weight: 400;
		color: #ffffff;
		font-size: 0.3rem;
		padding-top: 0.5rem;
	}
	
	.nactive-lists {
		position: relative;
		font-weight: 400;
		text-align: left;
		color: #ffffff;
		cursor: pointer;
		font-size: 1rem;
		letter-spacing: 0px;
		padding: 0.5rem 4rem 0.5rem 0.5rem;
		padding-bottom: 0.5rem !important;
	}
	
	.nactive-list {
		position: relative;
		padding-top: 0.2rem;
		font-weight: 400;
		text-align: left;
		color: #ffffff;
		font-size: 1rem;
		line-height: 30px;
		letter-spacing: 0px;
	}
	
	.page-ud img {
		width: 0.25rem;
	}
	
	.page_error {
		width: 1rem;
		position: absolute;
		right: 0.3rem;
		cursor: pointer;
	}
	
	.page_errors {
		width: 2rem;
		position: absolute;
		right: 0.3rem;
		top: 0.5rem;
		cursor: pointer;
	}
	
	.fl {
		float: left;
	}
	
	.fr {
		float: right;
	}
	
	.nactive-content .list_mew {
		width: 0.25rem;
		position: absolute;
		top: 0.6rem;
		left: 0;
	}
	
	.time {
		position: absolute;
		top: 0.5rem;
		right: 0;
	}
	
	.border-bottom {
		padding-bottom: 0.4rem;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	}
	
	.makeLogo {
		width: 2.5rem;
	}
	
	.make-box {
		position: fixed;
		right: 0;
		left: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 20;
		overflow: auto;
	}
	
	.box-list-box {
		position: relative;
		width: 70%;
		height: auto;
		margin: 5rem auto;
		padding: 2rem 1.5rem;
		background-color: rgba(0, 0, 0, 0.8);
	}
	/* 公告详情 */
	
	.title-time {
		font-size: 1rem;
		font-weight: 400;
		text-align: center;
		color: #ffffff;
		padding: 0.2rem 0;
	}
	
	.title-make {
		font-size: 1.4rem;
		font-weight: 600;
		text-align: center;
		color: #ffffff;
	}
	
	.header-top {
		width: 100%;
		height: auto;
	}
	
	.go-back {
		width: 2.5rem;
		cursor: pointer;
		height: 0.6rem;
		background: url(../../assets/img/go-back.png) no-repeat center;
		background-size: 100% 100%;
		position: absolute;
		left: 0.3rem;
		top: 0.5rem;
		font-size: 0.3rem;
		font-weight: 400;
		text-align: center;
		color: #ffffff;
		line-height: 1rem;
	}
	/* 结束end */
	
	.nav-container {
		position: fixed;
		font-size: 0.16rem;
		right: 0.6rem;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		height: 4.6rem;
		border-right: 1px solid rgba(170, 170, 170, 0.4);
		z-index: 2;
	}
	
	.nav-container .nav {
		position: relative;
		top: 38%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	
	.nav-container .nav > a {
		display: block;
		position: relative;
		color: #fff;
		text-align: right;
		padding-right: 0.5rem;
		margin: 0.5rem 0;
		text-decoration: underline;
	}
	
	.nav-container .nav > a:after {
		content: '';
		display: block;
		position: absolute;
		background-image: url(../../assets/img/nav.png);
		width: 0.3rem;
		height: 0.3rem;
		left: 100%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	
	.nav-container .nav > a.active {
		color: #7bf5e0;
	}
	
	.nav-container .nav > a:after {
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
	
	.nav-container .nav > a.active:after {
		background-image: url(../../assets/img/nav_sve.png);
		width: 0.37rem;
		height: 0.37rem;
	}
	
	.cover {
		min-height: 440px;
	}
	
	.phone-box {
		width: 265px;
		height: 440px;
		padding: 30px 28px;
		background: url(../../assets/img/phone-box.png) no-repeat center;
		background-size: 100% 100%;
		margin: 0 auto;
	}
	
	.phone-box .cover {
		border-radius: 14px;
		margin-left: -5px;
		margin-top: -1px;
		background-position: center !important;
	}
	
	.phone-boxs {
		transform: rotate(180deg);
		opacity: 0.4 !important;
	}
	
	.swiper {
		width: 100%;
		height: 380px;
	}
	
	.swiper-slide {
		display: flex;
		flex-direction: column;
		justify-content: center;
		color: $white;
		box-sizing: border-box;
		padding: 0 80px;
		background-color: transparent;
	}
	
	.title {
		margin-bottom: $lg-gap;
		font-size: $font-size-huge * 2;
		font-weight: bold;
	}
	
	.subtitle {
		margin-bottom: $gap;
		font-size: $font-size-base * 2;
	}
	
	.text {
		max-width: 430px;
		line-height: 1.32;
	}
	
	.warpper {
		width: 100%;
		height: auto;
		overflow: auto;
		background: #010714;
	}
	/* 头部 */
	
	.header {
		width: 100%;
		height: 60px;
		line-height: 60px;
		background: #fff;
	}
	
	.hd-box {
		width: 80%;
		/*max-width: 1200px;*/
		margin: 0 auto;
	}
	
	.hd-box > div {
		float: left;
	}
	
	.hd-box > div img {
		height: 36px;
		margin-top: 15px;
		float: left;
	}
	
	.hd-box > ul {
		width: auto;
		height: 100%;
		float: right;
	}
	
	.hd-box > ul li {
		width: auto;
		height: 100%;
		float: left;
	}
	
	.hd-box > ul li a {
		display: block;
		width: auto;
		height: 100%;
		margin: 0 20px;
		color: #333;
		font-size: 14px;
		transition: all 0.15s linear;
	}
	
	.hd-box > ul li a:hover {
		color: #57bdf5;
	}
	/* 页面 */
	
	.page-box {
		width: 100%;
		height: auto;
		overflow: auto;
	}
	
	.page-box p{
		font-size: 12px;
	}
	
	.page-box > li {
		position: relative;
		width: 100%;
		height: auto;
		color: #fff;
	}
	
	.page-box > li > div {
		position: relative;
		width: 100%;
		height: auto;
		margin: 0 auto;
		color: #fff;
		z-index: 2;
	}
	
	.page-box > li:nth-child(1) {
		width: 100%;
		min-height: 40rem;
		/* background: url(../../assets/img/page1-banner.png) no-repeat center; */
		background: url(https://gitee.com/krospath/imgs/raw/master/page1-banner.png) no-repeat center;
		background-size: 100% auto;
	}
	
	.page-box > li:nth-child(2) {
		width: 100%;
		min-height: 15rem;
		/* background: url(../../assets/img/page2-backsy.jpg) no-repeat center; */
		background: url(https://gitee.com/krospath/imgs/raw/master/page2-backsy.jpg) no-repeat center;
		background-size: cover;
	}
	
	.page-box > li:nth-child(3) {
		width: 100%;
		min-height: 15rem;
		/* background: url(../../assets/img/page2_cover.jpg) no-repeat center; */
		background: url(https://gitee.com/krospath/imgs/raw/master/page2_cover.jpg) no-repeat center; 
		background-size: cover;
	}
	
	.page-box > li:nth-child(4) {
		width: 100%;
		min-height: 15rem;
		/* background: url(../../assets/img/page3_cover.jpg) no-repeat center; */
		background: url(https://gitee.com/krospath/imgs/raw/master/page2_cover.jpg) no-repeat center; 
		background-size: cover;
	}
	
	.page-box > li:nth-child(5) {
		width: 100%;
		min-height: 15rem;
		/* background: url(../../assets/img/page4_cover.jpg) no-repeat center; */
		background: url(https://gitee.com/krospath/imgs/raw/master/page2_cover.jpg) no-repeat center; 
		background-size: cover;
	}
	
	.page-box > li:nth-child(6) {
		width: 100%;
		min-height: 7rem;
		/* background: url(../../assets/img/page5_cover.jpg) no-repeat center; */
		background: url(https://gitee.com/krospath/imgs/raw/master/page2_cover.jpg) no-repeat center; 
		background-size: cover;
	}
	
	.page1-bg-1 {
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: url(../../assets/img/page1_img1.png) no-repeat right;
		background-size: auto 100%;
	}
	
	.page01-bg-01 {
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: url(../../assets/img/page1_img1.png) no-repeat right;
		background-size: auto 100%;
	}
	
	.page2-bg-2 {
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: url(../../assets/img/page2_game_user.png) no-repeat right;
		background-size: auto 100%;
	}
	
	.main1 > *,
	.main01 > *,
	.main2 > *,
	.main3 > *,
	.main4 > * {
		margin-bottom: 0.1rem;
	}
	
	.main1-1 img,
	.main2-1 img {
		height: 5rem;
	}
	
	.main1-2 {
		width: 100%;
		height: 9rem;
		background: url(../../assets/img/page1_img3.png) no-repeat;
		background-size: 100% 9rem;
	}
	
	.main1-2 p:nth-child(1) {
		display: block;
		color: #12fff1;
		font-size: 2rem;
		font-weight: 550;
		font-style: italic;
		font-family: myFirstFont;
		padding-top: 2rem;
		text-align: center;
	}
	
	.main1-2 p:nth-child(2) img {
		height: 2rem;
		padding-top: 1rem;
		display: block;
		margin: 0 auto;
	}
	
	.main1-4 {
		width: 10rem;
		height: auto;
		padding: 0.2rem;
		background: url(../../assets/img/page1_img10.png) no-repeat;
		background-size: 10rem 100%;
		color: #fff;
		font-size: 0.4rem;
	}
	
	.main1-4 li {
		height: auto;
		line-height: 1;
		margin: 0.2rem 0;
	}
	
	.main1 li > * {
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	
	.main1 li span:nth-child(1) {
		width: 11%;
		color: #ffc731;
	}
	
	.main1 li a:nth-child(2) {
		width: 64%;
		color: #fff;
		font-weight: 500;
	}
	
	.main1 li span:nth-child(3) {
		width: 25%;
		text-align: right;
		font-size: 0.38rem;
	}
	
	.swiper-container {
		width: 100%;
		/*height: 100%!important;*/
		height: auto !important;
		margin: 0 auto;
		position: relative;
	}
	
	.swiper-slide {
		width: 100% !important;
		height: auto !important;
		min-height: 400px !important;
		/*transform: scale(1);*/
		transition-timing-function: ease;
		transition-duration: 300ms;
		/*display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;*/
		position: relative;
		/*padding: 0.14rem 0 0.11rem;*/
	}
	
	.main3 .swiper-slide {
		max-height: 670px !important;
		overflow: hidden;
	}
	
	.bg-cover {
		width: 100%;
		height: auto !important;
		min-height: 100% !important;
		font-size: 0.12rem;
		font-weight: bold;
		color: #366f5e;
		line-height: 0.18rem;
		/* background: #000;*/
		/*.point-text {
				color: red;
			}*/
	}
	
	.bg-cover .cover {
		width: 100%;
		/* height: auto; */
		background-repeat: no-repeat;
		/* background-position: center; */
		background-size: cover;
	}
	
	.slider-item.slider-active {
		transform: scale(1);
		z-index: 999;
	}
	
	.slider-item.slider-active-copy {
		transform: scale(1);
		z-index: 999;
	}
	
	.card-details-swiper .swiper-container {
		height: 100% !important;
	}
	
	.main2 .left_img {
		width: 12rem;
	}
	
	.page-title {
		display: block;
		padding: 2rem 0;
		width: 100%;
		margin: 0 auto;
		height: 3.2rem;
	}
	
	.title-box {
		width: 85%;
		margin: 0 auto;
	}
	
	.margin {
		height: 1rem;
	}
	
	.title-box .title-content {
		font-weight: 500;
		text-align: center;
		color: #e3e7e3;
		font-size: 1rem;
		line-height: 2rem;
	}
	a{
		color: #FFFFFF;
	}
	
	.footer {
		padding: 0.3rem 0 0.5rem;
		width: 100% !important;
		opacity: 0.8;
		background: #000000;
	}
	
	.footer .main5 {
		position: relative;
		width: 90%;
		height: auto;
		margin: 0 auto;
		color: #fff;
		z-index: 2;
	}
	
	.footer_logo {
		display: block;
		float: left;
		width: 1.5rem;
		margin: 0 1rem;
	}
	
	.footer-top {
		height: auto;
		overflow: hidden;
		margin: 0 auto;
		text-align: center;
	}
	.footer-top a{
		color: #FFFFFF;
	}
	.footer-title {
		float: left;
		font-size: 14px;
		font-weight: 400;
		color: #ffffff;
		padding-top: 0.2rem;
	}
	
	.footer-bottom {
		text-align: center;
/* 		font-size: 0.35rem; */
		font-weight: 400;
		padding-top: 20px;
		color: #ffffff;
	}
	

	.footer-bottom p:first-child {
		padding-bottom: 0.2rem;
	}
	
	.footer-margin {
		margin: 0 auto;
	}
	
	/deep/ .swiper-container-horizontal > .swiper-pagination-bullets {
		display: none;
	}
	
	/deep/ .main2 .swiper-slide {
		padding: 0 !important;
	}
	
	/deep/ .main2 .swiper-slide .cover {
		width: 100% !important;
		min-height: 48rem !important;
	}
	
	.swiper-button-next {
		background: url(../../assets/img/bannerH5-r.png) no-repeat;
	}
	
	.swiper-button-prev {
		background: url(../../assets/img/bannerH5-l.png) no-repeat;
	}
	
	.swiper-button-prev,
	.swiper-button-next {
		width: 5rem;
		height: 5rem;
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
	}
	
	.swiper-button-next,
	.swiper-button-prev,
	.swiper-button-nexts,
	.swiper-button-prevs {
		background-repeat: no-repeat !important;
		background-position: center !important;
		background-size: contain !important;
	}
	
	/deep/ .swiper-button-next:after,
	.swiper-container-rtl .swiper-button-prev:after {
		width: 4.5rem !important;
		height: 4.5rem !important;
		opacity: 0 !important;
	}
	
	/deep/ .swiper-button-prev:after,
	.swiper-container-rtl .swiper-button-next:after {
		width: 4.5rem !important;
		height: 4.5rem !important;
		opacity: 0 !important;
	}
	
	.swiper-button-nexts {
		width: 4.5rem;
		height: 4.5rem;
		background: url(../../assets/img/navJt-r.png);
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
	}
	
	.swiper-button-prevs {
		width: 4.5rem;
		height: 4.5rem;
		background: url(../../assets/img/navJt-l.png);
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
	}
</style>